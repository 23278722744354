<template>
  <div>
      <b-form>
          <b-row class="mb-1">
              <b-col>
                  <h2>
                      Busca la finca de carbono a gestionar
                  </h2>
              </b-col>
          </b-row>
          <b-row>
              <b-col md="2" xl="2" class="mb-1">
                  <b-form-group label="Nombre Finca" label-for="basicInput">
                      <b-form-input v-model="farmName" placeholder="Digite el Nombre de Finca"></b-form-input>
                  </b-form-group>
              </b-col>
              <b-col md="2" xl="2" class="mb-1">
                    <b-form-group label="Fase" label-for="basicInput">                        
                        <b-form-select @change="onFaseSelect" :options="fasesList" 
                        value-field="farmStatus" text-field="farmStatus" placeholder="Escoja la Fase"/>
                    </b-form-group>
                </b-col>
              <b-col md="2" xl="2" class="mt-2">
                  <b-button variant="primary" @click="getAllCarbonFarms">
                      <feather-icon icon="SearchIcon" class="mr-50" />Filtrar
                  </b-button>
              </b-col>
              <b-col md="2" xl="2" class="mt-2 text-right" offset-lg="4">
                  <b-button variant="warning" @click="addNewFarm" block>
                      <feather-icon icon="PlusIcon" class="mr-50" />Crear Finca
                  </b-button>
              </b-col>
          </b-row>
      </b-form>

      <b-card-code :title="'Lista de fincas de carbono (Cantidad: ' + carbonFarms.length + ')'" no-body>

          <b-table
            ref="selectableTable"
            :fields="fields"
            :items="carbonFarms"
            responsive
            selectable
            class="mb-0"
            :select-mode="selectMode"
            @row-selected="onRowSelected">
                <template #cell(farmName)="data">
                    {{ (data.item.farmName) }}
                </template>

                <template #cell(productorName)="data">
                    {{ (data.item.firstName) }} {{ (data.item.lastName) }}
                </template>

                <template #cell(farmStatus)="data">
                    {{ data.item.farmStatus }}
                </template>

                  <!-- <template #cell(advisor)="data">
                    <b-badge v-if="data.item.advisor == 'CEG'" pill variant="warning" >{{ data.item.advisor }}</b-badge>
                    <b-badge v-if="data.item.advisor == 'Sosty'" pill variant="success" >{{ data.item.advisor }}</b-badge>
                    <b-badge v-if="data.item.advisor == 'CNG'" pill variant="info" >{{ data.item.advisor }}</b-badge>
                </template> -->
          </b-table>

      </b-card-code>
      <b-row v-if="this.carbonFarms.length <= 0" class="justify-content-center mt-3" style="text-align:center">
          <b-col style="justify-content:center" xl="4" md="6" lg="4">
              <b-alert variant="primary" show>
                  <div class="alert-body">
                      <span><strong>No Hay Información para mostrar</strong></span>
                  </div>
              </b-alert>
          </b-col>
      </b-row>

      <b-modal centered ref="buttons-carbon-farm-modal" hide-footer title="¿Que deseas hacer?">
            <b-button class="mt-2" variant="primary" block @click="editCarbonFarm">
                <feather-icon icon="EditIcon" class="mr-50" />
                <span class="align-middle">Editar Finca</span>
            </b-button>
            <b-button class="mt-2" variant="outline-primary" block @click="viewDashboard">
                <feather-icon icon="EditIcon" class="mr-50" />
                <span class="align-middle">Ver Dashboard</span>
            </b-button>
            <b-button class="mt-2" variant="warning" block @click="viewRegisterMetrics">
                <feather-icon icon="EditIcon" class="mr-50" />
                <span class="align-middle">Ver Capturas y Emisiones</span>
            </b-button>
            <b-button class="mt-2" variant="secondary" block @click="viewCarbonSales">
                <feather-icon icon="EditIcon" class="mr-50" />
                <span class="align-middle">Ver Venta de Creditos</span>
            </b-button>
            <b-button class="mt-2" variant="info" block @click="viewFarmEvent">
                <feather-icon icon="EditIcon" class="mr-50" />
                <span class="align-middle">Ver Eventos Monitoreo</span>
            </b-button>
            <b-button class="mt-2" variant="danger" block @click="viewChecklist">
                <feather-icon icon="EditIcon" class="mr-50" />
                <span class="align-middle">Ver Checklist</span>
            </b-button>
        </b-modal>

      <b-modal size="lg" ref="farmModal" centered hide-footer title="Digite la siguiente información">
          <b-card-text>
              <b-form>             
                <b-row>
                    <b-col md="4" xl="4" class="mb-0">
                        <label>Nombre finca:</label>
                        <b-form-input v-model="carbonFarmName" class="mb-1" placeholder="Digite" />
                    </b-col>

                    <b-col md="4" xl="4" class="mb-0">
                        <label>Código</label>
                        <b-form-input v-model="farmCode" placeholder="Digite" />
                    </b-col>

                    <b-col md="4" xl="4" class="mb-0">
                        <b-form-group label="Fase" label-for="basicInput">
                        <b-form-select @change="phaseSelected" v-model="farmStatus" :options="fasesList" placeholder="Seleccione" />
                        </b-form-group>
                    </b-col>
                </b-row>

                <app-collapse accordion>
                    <app-collapse-item title="Información Básica">
                        <b-row>
                            <b-col md="4" xl="4" class="mb-0">
                                <b-form-group label="Dirección" label-for="basicInput">
                                    <b-form-input v-model="address" placeholder="Digite" />
                                </b-form-group>
                            </b-col>

                            <b-col md="4" xl="4" class="mb-0">
                                <b-form-group label="Departamento" label-for="basicInput">
                                    <b-form-select @change="onDepartamentoSelect" v-model="state" :options="departamentosList"
                                        value-field="departamento" text-field="departamento" placeholder="Seleccione" />
                                </b-form-group>
                            </b-col>

                            <b-col md="4" xl="4" class="mb-0">
                                <b-form-group label="Municipio/Ciudad" label-for="basicInput">
                                    <b-form-select v-model="city" :options="municipiosList" placeholder="Seleccione" />
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>                      
                            <b-col md="4" xl="4" class="mb-0">
                                <b-form-group label="Latitud" label-for="basicInput">
                                    <b-form-input v-model="latitude" placeholder="Digite" />
                                </b-form-group>
                            </b-col>

                            <b-col md="4" xl="4" class="mb-0">
                                <b-form-group label="Longitud" label-for="basicInput">
                                    <b-form-input v-model="longitude" placeholder="Digite" />
                                </b-form-group>
                            </b-col>

                            <b-col md="4" xl="4" class="mb-1">
                                <b-form-group label="Link Polígono Finca" label-for="basicInput">
                                    <b-form-input v-model="farmPolygon" placeholder="Digite" />
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col md="12" xl="12" class="mb-1">
                                <label>Detalles Ubicación</label>
                                <b-form-textarea v-model="addressIndications" id="textarea-default" placeholder="Digite Detalles" rows="2"/>
                            </b-col> 
                        </b-row>

                        <b-row>
                            <b-col md="6" xl="6" class="mb-1">
                                <b-form-checkbox v-model="isRented" checked="false" name="check-button" switch inline>
                                    Es arrendada
                                </b-form-checkbox>
                            </b-col>
                            <b-col md="4" xl="4" class="mb-0">
                                <b-form-group label="Productor" label-for="basicInput">
                                    <select v-model="carbonProducerID" id="dropdown">
                                        <option v-for="item in carbonProducers" :key="item.carbonProducerID" :value="item.carbonProducerID">{{ item.firstName }} {{ item.lastName }}</option>
                                    </select>
                                </b-form-group>
                            </b-col> 
                        </b-row>                
                    </app-collapse-item>                        

                    <app-collapse-item title="Información Proyecto">
                        <b-row>                    
                            <b-col md="6" xl="6" class="mb-1">
                                <label for="example-datepicker">Fecha de Transición</label>
                                <b-form-datepicker :show-decade-nav="true" v-model="transitionDate" id="example-datepicker2" class="mb-1"/>
                            </b-col>
                            <b-col md="6" xl="6" class="mb-1">
                                <label for="example-datepicker">Fecha de Inicio</label>
                                <b-form-datepicker :show-decade-nav="true" v-model="initDate" id="example-datepicker2" class="mb-1"/>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col md="4" xl="4" class="mb-0">
                                <b-form-group label="Patrocinador" label-for="basicInput">
                                <b-form-select @change="advisorSelected" v-model="advisor" :options="advisorsList" placeholder="Seleccione" />
                                </b-form-group>
                            </b-col>

                            <b-col md="4" xl="4" class="mb-0">
                                <b-form-group label="Tamaño Ha" label-for="basicInput">
                                    <b-form-input v-model="sizeHa" placeholder="Digite" />
                                </b-form-group>
                            </b-col>

                            <b-col md="4" xl="4" class="mb-0">
                                <b-form-group label="Hectáreas Pastura" label-for="basicInput">
                                    <b-form-input v-model="haGrass" placeholder="Digite" />
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col md="4" xl="4" class="mb-0">
                                <b-form-group label="Hectáreas Bosque" label-for="basicInput">
                                    <b-form-input v-model="haForest" placeholder="Digite" />
                                </b-form-group>
                            </b-col>

                            <b-col md="4" xl="4" class="mb-0">
                                <b-form-group label="Hectáreas Bosque Elegible" label-for="basicInput">
                                    <b-form-input v-model="haForestAvailable" placeholder="Digite" />
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row style="justify-content:left">
                            <b-col md="12" xl="12" class="mb-0">
                                <b-form-group>
                                    <label>Los próximos tres (3) campos son de selección múltiple, presione tecla ctrl o cmd para seleccionar</label>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col md="4" xl="4" class="mb-0">
                                <b-form-group label="Tipo de Rotación/Manejo" label-for="basicInput">                        
                                    <b-form-select multiple v-model="typeOfGrazing" :options="grazingList" :select-size="7" placeholder="Seleccione" />
                                </b-form-group>
                            </b-col>

                            <b-col md="4" xl="4" class="mb-0">
                                <b-form-group label="Tipo de Ganado" label-for="basicInput">
                                    <b-form-select multiple v-model="typeOfBovine" :options="bovineList" :select-size="5" placeholder="Seleccione" />
                                </b-form-group>
                            </b-col>

                            <b-col md="4" xl="4" class="mb-0">                        
                                <b-form-group label="Tipo de Proyecto" label-for="basicInput">
                                    <b-form-select multiple v-model="typeOfProject" :options="projectList" :select-size="5" placeholder="Seleccione" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </app-collapse-item>
                    
                    <app-collapse-item title="Documentos" v-if="this.carbonFarmID != null">
                        <b-row v-if="this.carbonFarmID != null" class="justify-content-end">
                            <b-col class="mb-0">
                                <b-form-group>
                                    <label>Documentos <b-button variant='white' @click="showAddNewDocumentModal">
                                        <feather-icon class="text-success mr-1" icon="FilePlusIcon" size="26" />
                                        </b-button>
                                    </label>                             
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row v-if="this.carbonFarmID != null" style="justify-content:left">
                            <b-col md="12" xl="12" class="mb-0">
                                    <label>Lista de Documentos</label>
                            </b-col>
                            <b-col v-if="this.carbonFarmDocuments.length > 0" md="12" xl="12"  class="mb-0">                        
                                <b-table 
                                ref="selectableTable"
                                :fields="fieldsCarbonFarmDocument"
                                :items="carbonFarmDocuments"
                                responsive
                                selectable
                                class="mb-0"
                                :select-mode="selectMode"
                                @row-selected="onRowCarbonFarmDocumentSelected"
                                thead-class="hidden_header">                        
                                    <template #cell(createDate)="data">
                                        
                                        <div style="display: flex; justify-content: left;">
                                            {{ data.item.createDate.substring(0,10) }} - {{ data.item.fileDescription }}                                   
                                            <b-button variant='white' style="padding:4px" @click="openDocument(data.item.fileDocumentUrl)" v-if="data.item.fileDocumentUrl">
                                            <feather-icon class="text-success" icon="DownloadIcon" size="16" />
                                            </b-button><b-button style="padding:4px;" variant='white' @click="showDeleteCarbonFarmDocumentModal(data.item.carbonFarmDocumentID)">
                                            <feather-icon class="text-danger" icon="TrashIcon" size="16" />
                                            </b-button>
                                        </div>                                
                                    </template>
                                        
                                </b-table>
                            </b-col>

                            <b-col v-else>
                                <p class="mt-2">Esta Finca aún no tiene documentos...</p>
                            </b-col>
                        </b-row>
                    </app-collapse-item>

                    <app-collapse-item title="Fechas de Fases">
                        <b-row>                    
                            <b-col md="4" xl="4" class="mb-1">
                                <label for="example-datepicker">Fecha 1. Pre-Registro</label>
                                <b-form-datepicker :show-decade-nav="true" v-model="stage1Date" id="example-datepicker2" class="mb-1"/>
                            </b-col>
                            <b-col md="4" xl="4" class="mb-1">
                                <label for="example-datepicker">Fecha 2. Comprobación de Integridad de Documentos</label>
                                <b-form-datepicker :show-decade-nav="true" v-model="stage2Date" id="example-datepicker2" class="mb-1"/>
                            </b-col>
                            <b-col md="4" xl="4" class="mb-1">
                                <label for="example-datepicker">Fecha 3. En Cola</label>
                                <b-form-datepicker :show-decade-nav="true" v-model="stage3Date" id="example-datepicker2" class="mb-1"/>
                            </b-col>
                        </b-row>

                        <b-row>                    
                            <b-col md="4" xl="4" class="mb-1">
                                <label for="example-datepicker">Fecha 4. Revisión Técnica 1</label>
                                <b-form-datepicker :show-decade-nav="true" v-model="stage4Date" id="example-datepicker2" class="mb-1"/>
                            </b-col>
                            <b-col md="4" xl="4" class="mb-1">
                                <label for="example-datepicker">Fecha 5. Revisión Legal</label>
                                <b-form-datepicker :show-decade-nav="true" v-model="stage5Date" id="example-datepicker2" class="mb-1"/>
                            </b-col>
                            <b-col md="4" xl="4" class="mb-1">
                                <label for="example-datepicker">Fecha 6. En espera de respuesta del productor</label>
                                <b-form-datepicker :show-decade-nav="true" v-model="stage6Date" id="example-datepicker2" class="mb-1"/>
                            </b-col>
                        </b-row>

                        <b-row>                    
                            <b-col md="4" xl="4" class="mb-1">
                                <label for="example-datepicker">Fecha 7. Revisión Técnica 2</label>
                                <b-form-datepicker :show-decade-nav="true" v-model="stage7Date" id="example-datepicker2" class="mb-1"/>
                            </b-col>
                            <b-col md="4" xl="4" class="mb-1">
                                <label for="example-datepicker">Fecha 8. Aviso aceptación/rechazo</label>
                                <b-form-datepicker :show-decade-nav="true" v-model="stage8Date" id="example-datepicker2" class="mb-1"/>
                            </b-col>
                            <b-col md="4" xl="4" class="mb-1">
                                <label for="example-datepicker">Fecha 9. Fase Contractual</label>
                                <b-form-datepicker :show-decade-nav="true" v-model="stage9Date" id="example-datepicker2" class="mb-1"/>
                            </b-col>
                        </b-row>

                        <b-row>                    
                            <b-col md="4" xl="4" class="mb-1">
                                <label for="example-datepicker">Fecha 10. Fase Monitoreo</label>
                                <b-form-datepicker :show-decade-nav="true" v-model="stage10Date" id="example-datepicker2" class="mb-1"/>
                            </b-col>
                            <b-col md="4" xl="4" class="mb-1">
                                <label for="example-datepicker">Fecha 11. Retiro</label>
                                <b-form-datepicker :show-decade-nav="true" v-model="stage11Date" id="example-datepicker2" class="mb-1"/>
                            </b-col>
                        </b-row>
                    </app-collapse-item>

                    <app-collapse-item title="Pilares">
                        <b-row>
                            <b-col md="4" xl="4" class="mb-0">
                                <b-form-group label="Pilar 1: Productividad Ganadera" label-for="basicInput">
                                    <b-form-input v-model="pilar1" placeholder="Digite" />
                                </b-form-group>
                            </b-col>

                            <b-col md="4" xl="4" class="mb-0">
                                <b-form-group label="Pilar 2: Socio-económico" label-for="basicInput">
                                    <b-form-input v-model="pilar2" placeholder="Digite" />
                                </b-form-group>
                            </b-col>

                            <b-col md="4" xl="4" class="mb-0">
                                <b-form-group label="Pilar 3: Regeneración Ambiental" label-for="basicInput">
                                    <b-form-input v-model="pilar3" placeholder="Digite" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                            
                        <b-row>
                            <b-col md="4" xl="4" class="mb-0">
                                <b-form-group label="Pilar 4: Dinámica de Participación" label-for="basicInput">
                                    <b-form-input v-model="pilar4" placeholder="Digite" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </app-collapse-item>
                </app-collapse>
                  
                                

                

                <b-row class="justify-content-end">
                    <b-col v-if="!processing" class="mt-1 mb-1" md="12" lg="12">
                        <b-button variant="primary" @click="saveCarbonFarm" block>
                            Guardar
                        </b-button>
                    </b-col>
                    <b-col v-if="processing" class="mt-1 mb-1" md="12" lg="12">
                        <b-button variant="primary" disabled block>
                            <b-spinner small label="Cargando..." />
                        </b-button>
                    </b-col>
                </b-row>
              </b-form>
          </b-card-text>
      </b-modal>
      
      <b-modal size="sm" ref="add-new-document-modal" centered hide-footer title="Digite la siguiente información">
        <b-card-text>
            <b-form>
                <b-row>
                    <b-col md="12" xl="12" class="mb-1">
                        <label>Descripción</label>
                        <b-form-input v-model="fileDescription" label-for="basicInput" placeholder="Digite Descripción" rows="2"/>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col md="12" xl="12" class="mb-0">
                        <b-form-group label="Documento" label-for="basicInput">
                            <b-form-file placeholder="Seleccione un documento" v-model="documentFile" />
                            <b-card-text @click="openDocument(fileDocumentUrl)" v-if="fileDocumentUrl"
                                class="cursor-pointer sosty-font-smaller">                            
                            </b-card-text>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row class="justify-content-end">
                    <b-col v-if="!processing" class="mt-1 mb-1" md="12" lg="12">
                    <b-button variant="primary" @click="saveCarbonFarmDocuments" block>
                        <feather-icon icon="SaveIcon"/> Guardar Documento
                    </b-button>
                    </b-col>
                    <b-col v-if="processing" class="mt-1 mb-1" md="12" lg="12">
                    <b-button variant="primary" disabled block>
                        <b-spinner small label="Cargando..." />
                    </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </b-card-text>
      </b-modal>

      <b-modal size="sm" ref="delete-carbon-farm-document-modal" centered hide-footer title="¿Está seguro que desea eliminar este documento?">
        <b-card-text>
            <b-form>

                <b-row class="justify-content-end">
                    <b-col v-if="!processing" class="mt-1 mb-1" md="12" lg="12">
                    <b-button variant="danger" @click="deleteCarbonFarmDocuments()" block>
                        <feather-icon icon="XIcon"/> Eliminar Documento
                    </b-button>
                    </b-col>
                    <b-col v-if="processing" class="mt-1 mb-1" md="12" lg="12">
                    <b-button variant="danger" disabled block>
                        <b-spinner small label="Cargando..." />
                    </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </b-card-text>
      </b-modal>
  </div>
</template>

<script>

import {
  BLink, BButton, BImg, BSpinner, BRow, BCol, BFormInput, BFormTextarea, BFormGroup,
  BTable, BAlert, BBadge, BFormFile, BForm, BFormSelect, BFormCheckbox, BCardText, BFormDatepicker
} from 'bootstrap-vue';
import BCardCode from '@/@core/components/b-card-code/BCardCode.vue';
import Cleave from 'vue-cleave-component';
import departamentos from '../../assets/departamentos-municipios.json';
import axios from '@axios'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
      BAlert,
      BLink,
      BButton,
      BCardText,
      BImg,
      BSpinner,
      BBadge,
      BRow,
      BCol,
      BForm,
      BFormInput,
      BFormTextarea,
      BFormGroup,
      BFormFile,
      BTable,
      BCardCode,
      BFormSelect,
      BFormCheckbox,
      BFormDatepicker,
      AppCollapse,
      AppCollapseItem,     
      Cleave
  },
  data() {
      return {
          selectMode: 'single',
          fields: [
              { key: 'line', label: '#' },
              { key: 'farmName', label: 'Nombre finca' },
              { key: 'farmCode', label: 'Código' },
              { key: 'productorName', label: 'Nombre productor' },
              { key: 'farmStatus', label: 'Fase' }
          ],
          fieldsCarbonFarmDocument: [
              { key: 'createDate', label: 'Fecha', trClass: 'styleDocumentTable'  }
          ],
            fasesList: [
                ' ',
                '1. Pre-Registro',
                '2. Comprobación de Integridad de Documentos',
                '3. En Cola',
                '4. Revisión Técnica 1',
                '5. Revisión Legal',
                '6. En espera de respuesta del productor',
                '7. Revisión Técnica 2',
                '8. Aviso aceptación/rechazo',
                '9. Fase Contractual',
                '10. Fase Monitoreo',
                '11. Retiro'
          ],
            advisorsList: [
                'CEG',
                'Sosty',
                'CNG'
          ],
            grazingList: [
                'Regenerativo',
                'PRV',
                'PUAD',
                'Holistico',
                'Rotacional',
                'Silvopastoril',
                'Agroforesta'
          ],
            bovineList: [
                'Vacuno',
                'Bufalino',
                'Otros'
          ],
            projectList: [
                'Cria',
                'Levante',
                'Ceba',
                'Ciclo Completo',
                'Doble Proposito',
                'Lecheria'
          ],
          carbonFarms: [{}],
          carbonProducers: [{}],
          carbonFarmDocuments: [{}],
          carbonFarmID: null,
          carbonFarmDocumentID: null,
          farmName: null,
          farmCode: null,
          carbonFarmName: null,
          state: null,
          city: null,
          address: null,
          addressIndications: null,
          latitude: null,
          longitude: null,
          sizeHa: null,
          haGrass: null,
          haForest: null,
          haForestAvailable: null,
          pilar1: null,
          pilar2: null,
          pilar3: null,
          pilar4: null,
          farmPolygon: null,
          transitionDate: null,
          isRented: null,
          advisor: null,
          initDate: null,
          phoneNumber: null,
          typeOfGrazing: [],
          typeOfBovine: [],
          typeOfProject: [],
          fileDescription: null,
          fileDocumentUrl: null,
          documentFile: null,     
          farmStatus: null,
          firstName: null,
          lastName: null,
          carbonProducerID: null,
          selectedCarbonFarm: {},
          selectedCarbonFarmDocument: {},
          createDate: null,
          fileDescription: null,
          departamentosList: departamentos,
          departamentoSelected: '',
          municipiosList: [],
          processing: null,
          documentSelect: null,
          userSelected: null,
          stage1Date: null,
          stage2Date: null,
          stage3Date: null,
          stage4Date: null,
          stage5Date: null,
          stage6Date: null,
          stage7Date: null,
          stage8Date: null,
          stage9Date: null,
          stage10Date: null,
          stage11Date: null,
          selectFarm: null
      }
  },
  created() {
      this.getAllCarbonFarms()
      this.getAllCarbonProducers()
  },
  
  watch:{
        documentFile: function(val) { this.documentFile = val; this.uploadFile(val, 'documentFile'); },
  },

  methods: {
      getAllCarbonFarms() {
          axios.get('/CarbonFarm/GetAllCarbonFarms', {
              params: {
                farmName: this.farmName, farmStatus: this.farmStatus
              }
          })
              .then(response => {
                  this.carbonFarms = response.data;
                  this.carbonFarms.forEach(item => {
                        item.line = this.carbonFarms.indexOf(item) + 1
                    });
              })
      },

      getAllCarbonProducers() {
            axios.get('/CarbonProducer/GetAllCarbonProducers', {
                
            })
                .then(response => {
                    this.carbonProducers = response.data;
                })
      },

      getAllCarbonFarmDocuments() {
        if(!this.selectedCarbonFarm) this.selectedCarbonFarm = this.selectFarm
                axios.get('/CarbonFarmDocument/GetAllCarbonFarmDocuments', {
                params: {
                    carbonFarmID: this.selectedCarbonFarm.carbonFarmID
                }                
            })
                .then(response => {
                    this.carbonFarmDocuments = response.data;
                })
      },
      
      onRowSelected(items) {
        if(items[0]) this.selectFarm = items[0]
        this.userSelected = items[0];   
        this.selectedCarbonFarm = items[0];
        if (this.selectedCarbonFarm) this.$refs['buttons-carbon-farm-modal'].show()
      },
      
      onRowCarbonFarmDocumentSelected(items) {
        this.selectedCarbonFarmDocument = items[0];
      },

      onFaseSelect(event){
        this.farmStatus = event;
      },

      addNewFarm() {
            this.processing = false;
                this.carbonFarmID = undefined;
                this.carbonFarmName = null,
                this.farmCode = null,
                this.farmStatus = null,
                this.state = null,
                this.city = null,
                this.address = null,
                this.addressIndications = null,
                this.latitude = null,
                this.longitude = null,               
                this.sizeHa = null,
                this.haGrass = null,
                this.haForest = null,
                this.haForestAvailable = null,
                this.pilar1 = null,
                this.pilar2 = null,
                this.pilar3 = null,
                this.pilar4 = null,
                this.farmPolygon = null,               
                this.transitionDate = null,
                this.isRented = null,
                this.advisor = null,
                this.initDate = null,
                this.typeOfGrazing = [],
                this.typeOfBovine = [],
                this.typeOfProject = [],
                this.stage1Date = null,
                this.stage2Date = null,
                this.stage3Date = null,
                this.stage4Date = null,
                this.stage5Date = null,
                this.stage6Date = null,
                this.stage7Date = null,
                this.stage8Date = null,
                this.stage9Date = null,
                this.stage10Date = null,
                this.stage11Date = null,
                this.$refs['farmModal'].show()
      },
      
      advisorSelected(event){
            this.advisor = event;
      },
      
      phaseSelected(event){
            this.farmStatus = event;
      },

      onDepartamentoSelect(event) {
        this.state = event;
        var departamento = this.departamentosList.find(x => x.departamento === event);
        this.municipiosList = departamento.ciudades;
      },
      
      saveCarbonFarm() {
            this.processing = true;
            var payload = {
                carbonFarmID: this.carbonFarmID,
                carbonProducerID: this.carbonProducerID,
                farmName: this.carbonFarmName,
                farmCode: this.farmCode,
                farmStatus: this.farmStatus,
                state: this.state,
                city: this.city,
                address: this.address,
                addressIndications: this.addressIndications,
                latitude: Number(this.latitude),
                longitude: Number(this.longitude),               
                sizeHa: Number(this.sizeHa),
                haGrass: Number(this.haGrass),
                haForest: Number(this.haForest),
                haForestAvailable: Number(this.haForestAvailable),
                pilar1: Number(this.pilar1),
                pilar2: Number(this.pilar2),
                pilar3: Number(this.pilar3),
                pilar4: Number(this.pilar4),
                farmPolygon: this.farmPolygon,
                transitionDate: this.transitionDate,
                isRented: this.isRented,
                advisor: this.advisor,
                initDate: this.initDate,
                typeOfGrazing: this.typeOfGrazing.toString(),
                typeOfBovine: this.typeOfBovine.toString(),
                typeOfProject: this.typeOfProject.toString(),
                stage1Date: this.stage1Date,
                stage2Date: this.stage2Date,
                stage3Date: this.stage3Date,
                stage4Date: this.stage4Date,
                stage5Date: this.stage5Date,
                stage6Date: this.stage6Date,
                stage7Date: this.stage7Date,
                stage8Date: this.stage8Date,
                stage9Date: this.stage9Date,
                stage10Date: this.stage10Date,
                stage11Date: this.stage11Date,
            }
            
            axios.post('/CarbonFarm/SaveCarbonFarm', payload)
                .then(response => {
                    this.processing = false;
                    this.$refs['farmModal'].hide();
                    this.$refs['buttons-carbon-farm-modal'].hide()
                    this.$bvToast.toast("La información ha sido guardada", { title: 'Ok', variant: 'success', solid: true })
                    this.getAllCarbonFarms();
                })
                .catch(error => {
                    this.processing = false;
                    if (error.response) {
                        this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
                    } else {
                        this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
                    }
                })
      },

      saveCarbonFarmDocuments() {
        this.processing = true;
        var payload = {
            carbonFarmID: this.carbonFarmID,
            fileDocumentUrl: this.fileDocumentUrl,
            fileDescription: this.fileDescription
        }
            axios.post('/CarbonFarmDocument/SaveCarbonFarmDocument', payload)
            .then(response => {
            this.processing = false;
            if(response.data) {
                this.fileDescription = null;
                this.$bvToast.toast("Documento guardado de forma exitosa", { title: 'Ok', variant: 'success', solid: true, });
                this.hideAddNewDocumentModal();
                this.getAllCarbonFarmDocuments();
            } else {
                this.fileDescription = null;
                this.$bvToast.toast("Ocurrio un problema", { title: 'Email', variant: 'danger', solid: true, })     
            }
            })
            .catch(error => {
                this.fileDescription = null;
                this.processing = false;
                if(error.response){
                this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
                } else {
                this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
                }
            })
      },

      deleteCarbonFarmDocuments() {
        this.processing = true;
            axios.get('/CarbonFarmDocument/DeleteCarbonFarmDocument', {
                params: {
                    carbonFarmDocumentID: this.documentSelect
                }
            })
            .then(response => {
            this.processing = false;
            if(response.data) {
                this.documentSelect = null
                this.getAllCarbonFarmDocuments();
                this.$bvToast.toast("Documento eliminado de forma exitosa", { title: 'Ok', variant: 'success', solid: true, });
                this.hideDeleteCarbonFarmDocumentModal();
            } else {
                this.documentSelect = null
                this.$bvToast.toast("Ocurrio un problema", { title: 'Email', variant: 'danger', solid: true, })     
            }
            })
            .catch(error => {
                this.documentSelect = null
                this.processing = false;
                if(error.response){
                this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
                } else {
                this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
                }
            })
      },
      
      editCarbonFarm() {
        this.carbonFarmID = this.selectedCarbonFarm.carbonFarmID;
        this.carbonProducerID = this.selectedCarbonFarm.carbonProducerID;
        this.carbonFarmName = this.selectedCarbonFarm.farmName;
        this.farmCode = this.selectedCarbonFarm.farmCode;
        this.farmStatus = this.selectedCarbonFarm.farmStatus;
        this.state = this.selectedCarbonFarm.state;
        var departamento = this.departamentosList.find(x => x.departamento === this.state);
        this.municipiosList = departamento.ciudades;
        this.city = this.selectedCarbonFarm.city;
        this.address = this.selectedCarbonFarm.address;
        this.addressIndications = this.selectedCarbonFarm.addressIndications;
        this.latitude = this.selectedCarbonFarm.latitude;
        this.longitude = this.selectedCarbonFarm.longitude;        
        this.sizeHa = this.selectedCarbonFarm.sizeHa;
        this.haGrass = this.selectedCarbonFarm.haGrass;
        this.haForest = this.selectedCarbonFarm.haForest;
        this.haForestAvailable = this.selectedCarbonFarm.haForestAvailable;
        this.pilar1 = this.selectedCarbonFarm.pilar1;
        this.pilar2 = this.selectedCarbonFarm.pilar2;
        this.pilar3 = this.selectedCarbonFarm.pilar3;
        this.pilar4 = this.selectedCarbonFarm.pilar4;
        this.farmPolygon = this.selectedCarbonFarm.farmPolygon;
        this.transitionDate = this.selectedCarbonFarm.transitionDate;
        this.isRented = this.selectedCarbonFarm.isRented;
        this.advisor = this.selectedCarbonFarm.advisor;
        this.initDate = this.selectedCarbonFarm.initDate;
        this.typeOfGrazing = this.selectedCarbonFarm.typeOfGrazing.split(',');       
        this.typeOfBovine = this.selectedCarbonFarm.typeOfBovine.split(',');
        this.typeOfProject = this.selectedCarbonFarm.typeOfProject.split(',');
        this.stage1Date = this.selectedCarbonFarm.stage1Date;
        this.stage2Date = this.selectedCarbonFarm.stage2Date;
        this.stage3Date = this.selectedCarbonFarm.stage3Date;
        this.stage4Date = this.selectedCarbonFarm.stage4Date;
        this.stage5Date = this.selectedCarbonFarm.stage5Date;
        this.stage6Date = this.selectedCarbonFarm.stage6Date;
        this.stage7Date = this.selectedCarbonFarm.stage7Date;
        this.stage8Date = this.selectedCarbonFarm.stage8Date;
        this.stage9Date = this.selectedCarbonFarm.stage9Date;
        this.stage10Date = this.selectedCarbonFarm.stage10Date;
        this.stage11Date = this.selectedCarbonFarm.stage11Date;

        this.getAllCarbonFarmDocuments();
        this.$refs['farmModal'].show()
      },
            
      uploadFile(fileToUpload, fileType) {
            this.processing = true;
            let formData = new FormData();
            formData.append('file', fileToUpload);
            axios.post('/File/UploadFile', formData, { headers: { 'Content-Type': 'multipart/form-data' } })                
                .then(response => {
                    this.processing = false;
                    if (response.data) {
                        this.setFileUrl(fileType, response.data);
                        this.$bvToast.toast("Documento subido de forma correcta", { title: 'Ok', variant: 'success', solid: true, });
                    } else {
                        this.$bvToast.toast("Ocurrio un problema", { title: 'Email', variant: 'danger', solid: true, })
                    }
                })
                .catch(error => {
                    this.processing = false;
                    if (error.response) {
                        this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
                    } else {
                        this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
                    }
                })
      },
      
      setFileUrl(fileType, fileUrl) {
            switch (fileType) {
                case 'documentFile':
                    this.fileDocumentUrl = fileUrl;
                    break;
            }
      },

      openDocument(fileUrl) {
            window.open(fileUrl, '_blank');
      },

      viewDashboard(){
        this.$refs['buttons-carbon-farm-modal'].hide()
        let route = this.$router.resolve({ path: '/dashboard?farmCode=' + this.selectedCarbonFarm.farmCode });
        window.open(route.href, '_blank');
      },
      viewRegisterMetrics(){
        this.$refs['buttons-carbon-farm-modal'].hide()
        this.$router.push('/register-metrics?carbonFarmID=' + this.selectedCarbonFarm.carbonFarmID)
      },
      viewCarbonSales(){
        this.$refs['buttons-carbon-farm-modal'].hide()
        this.$router.push('/carbon-sales?carbonFarmID=' + this.selectedCarbonFarm.carbonFarmID)
      },
      viewFarmEvent(){
        this.$refs['buttons-carbon-farm-modal'].hide()
        let route = this.$router.resolve({path: 'farm-event?carbonFarmID=' + this.selectedCarbonFarm.carbonFarmID});
        window.open(route.href, '_blank');
      },
      viewChecklist(){
        this.$refs['buttons-carbon-farm-modal'].hide()
        this.$router.push('/farms-checklist?carbonFarmID=' + this.selectedCarbonFarm.carbonFarmID)
      },
      
      showAddNewDocumentModal() {
      this.$refs['add-new-document-modal'].show()
      },
      
      hideAddNewDocumentModal() {
      this.$refs['add-new-document-modal'].hide()
      },
      
      showDeleteCarbonFarmDocumentModal(item) {
      this.$refs['delete-carbon-farm-document-modal'].show()
      this.documentSelect = item;
      },

      hideDeleteCarbonFarmDocumentModal() {
      this.$refs['delete-carbon-farm-document-modal'].hide()
      }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';

.hidden_header {
  display: none;
}

.flex-end {
  justify-content: flex-end;
}

.styleDocumentTable{
    height: 5px;
    width: 5px;
}
</style>